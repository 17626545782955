import React, { useState, useEffect } from "react";
import "./home.css";
import "./home.animations.css";
import Meniu from "../meniu/meniu";
import Reserve from "../reserve/reserve";

export default function Home() {
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isReserveVisible, setReserveVisible] = useState(false);

    const showMeniu = () => {
        setMenuVisible(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const hideMeniu = () => {
        setMenuVisible(false);
    };

    const showReserve = () => {
        setReserveVisible(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const hideReserve = () => {
        setReserveVisible(false);
    };

    // Use effect to add/remove class to body when Meniu is visible
    useEffect(() => {
        if (isMenuVisible) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isMenuVisible]);

    return (
        <>
            <div className="home_hero">
                <Meniu isVisible={isMenuVisible} hideMeniu={hideMeniu} />
                <Reserve isVisible={isReserveVisible} hideReserve={hideReserve} />
                <div className="hh_bg">
                    <div className="hh_content">
                        <div className="hh_social">
                            <a href="https://www.facebook.com/profile.php?id=61566595971602" target="_blank" rel="noreferrer">FACEBOOK</a>
                            <a href="https://www.instagram.com/niekotokio.catering" target="_blank" rel="noreferrer">INSTAGRAM</a>
                        </div>
                        <div className="hh_title">
                            <h2>Jau netrukus pasimatysime!</h2>
                            <h1>Japonijos įkvėptoje edukacinėje gastro kelionėje, jūsų namuose</h1>
                        </div>
                        <div className="hh_mail">
                            <a href="mailto:labas@niekotokio.catering">labas@niekotokio.catering</a>
                        </div>
                    </div>
                    <div className="hh_bottom">
                        <div className="hh_meniu" onClick={showMeniu}>
                            <img src="/images/buttons/meniu_button.svg" alt="meniu" />
                        </div>
                        <div className="hh_reserve" onClick={showReserve}>
                            <img src="/images/buttons/reserve_button.svg" alt="reserve" />
                        </div>
                    </div>
                    <div className="hh_shrimp">
                        <img className="desk_shrimp" src="/images/shrimp.webp" alt="shrimp" draggable="false" />
                        <img className="mobile_shrimp" src="/images/shrimp_mobile.webp" alt="shrimp" draggable="false" />
                    </div>
                    <div className="hh_koi">
                        <img className="desk_koi" src="/images/koi.webp" alt="koi" draggable="false" />
                        <img className="mobile_koi" src="/images/koi_mobile.webp" alt="koi" draggable="false" />
                    </div>
                </div>
                <div className="hh_mobile_content">
                    <div className="hh_social">
                        <a href="https://www.facebook.com/profile.php?id=61566595971602">FACEBOOK</a>
                        <a href="https://www.instagram.com/niekotokio.catering">INSTAGRAM</a>
                    </div>
                    <div className="hh_mail">
                        <a href="mailto:labas@niekotokio.catering">labas@niekotokio.catering</a>
                    </div>
                </div>
            </div>
        </>
    );
}